import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { get } from 'lodash';
import './styles.css';
import { Typography } from '@material-ui/core';
import { TablePrint, TableSimplePrint } from './table';
import {
    FIMColumns,
    FIMPayrol,
    FIMSummery,
    adjustmentColumns,
    sellerBonus,
    sellerColumns,
    sellerPayroll,
    sellerSummery,
    TMColumns,
    TMSPayrol,
    TMSummery,
    fimBonus,
} from './columns';

const dollar = (value) => {
    if (Number.isNaN(Number(value))) return '$0.00';
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
};

const getColumns = (type, associate) => {
    switch (type) {
        case 'summary':
            return associate.includes('seller')
                ? sellerSummery
                : associate.includes('fim')
                ? FIMSummery
                : TMSummery;
        case 'payroll':
            return associate.includes('seller')
                ? sellerPayroll
                : associate.includes('fim')
                ? FIMPayrol
                : TMSPayrol;
        case 'data':
            return associate.includes('seller')
                ? sellerColumns
                : associate.includes('fim')
                ? FIMColumns
                : TMColumns;
        case 'bonus':
            return associate.includes('seller')
                ? sellerBonus
                : associate.includes('fim')
                ? fimBonus
                : [];
        default:
            return [];
    }
};

const getDataHelper = (data, item) => {
    const value = get(data, item?.key);
    if (item?.whiteSpace && !value) return ' ';
    if (item?.dollar) return dollar(value);
    if (item?.percent) return `${value}%`;
    return value;
};

const getTableData = (keyList, data) => {
    const list = {};
    keyList.forEach((item) => {
        list[item.title] = getDataHelper(data, item);
    });
    return list;
};

export default (props) => {
    const { completeAssociateList, typeAssociate } = props;
    const isTM = typeAssociate.toLowerCase().includes('tm');
    const classes = useStyles();
    const date = () => {
        let dateNow = new Date();
        let month = dateNow.getMonth() + 1;
        let day = dateNow.getDate();
        let year = dateNow.getFullYear();
        const format = (n) => {
            return (n < 10 ? '0' : '') + n;
        };
        let h = dateNow.getHours();
        let m = dateNow.getMinutes();
        let s = dateNow.getSeconds();

        return (
            year +
            '/' +
            format(month) +
            '/' +
            format(day) +
            '--' +
            format(h) +
            ':' +
            format(m) +
            ':' +
            format(s)
        );
    };
    return (
        <div className='container-table'>
            {completeAssociateList?.map((item, index) => {
                return (
                    <table key={`${Math.random().toString(36)}`} className='report-container'>
                        <thead className='report-header'>
                            <tr>
                                <th className='report-header-cell'>
                                    <div className='header-info'>
                                        <div className='pageHeader' align='center'>
                                            <img
                                                height='50px'
                                                src='/images/logos/logoUpturnPrint.png'
                                            />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {/* <tfoot className='report-footer'>
                            <tr>
                                <td className='report-footer-cell'>
                                    <div className='footer-info'></div>
                                </td>
                            </tr>
                        </tfoot> */}
                        <tbody className='report-content'>
                            <tr>
                                <td className='report-content-cell'>
                                    <div className='main'>
                                        <div className='page-content-inside-auto container-table-1'>
                                            <Typography variant='h4'>
                                                {item?.title_data_associate}
                                            </Typography>
                                            <Typography
                                                variant='subtitle2'
                                                className={classes.subTitle}
                                            >
                                                {date()}
                                            </Typography>
                                            <TablePrint
                                                dataTable={{
                                                    columns: getColumns(
                                                        'data',
                                                        typeAssociate.toLowerCase()
                                                    ),
                                                    data: item?.data,
                                                }}
                                                data={item}
                                            />
                                        </div>

                                        {/* <div className="page-content-inside-auto container-table-2">
                                     <Typography
                                         variant="subtitle2"
                                         className={classes.subTitle}
                                     >
                                         New Car
                                     </Typography>
                                     <TablePrint
                                         // dataTable={dataTableDealsNew}
                                         // length={lengthTable.dataTableDealsNew}
                                     />
                                 </div> */}
                                        {/* <div className="page-content-inside-auto container-table-3">
                                     <Typography
                                         variant="subtitle2"
                                         className={classes.subTitle}
                                     >
                                         Used Car
                                     </Typography>
                                     <TablePrint
                                         // dataTable={dataTableDealsUsed}
                                         // length={lengthTable.dataTableDealsUsed}
                                     />
                                 </div> */}

                                        <div className='page-content-inside-always container-table-4'>
                                            <div className='columm-1'>
                                                <Typography
                                                    variant='subtitle2'
                                                    className={classes.subTitle}
                                                >
                                                    Adjustment
                                                </Typography>
                                                <TablePrint
                                                    dataTable={{
                                                        columns: adjustmentColumns,
                                                        data: item?.adjustments,
                                                    }}
                                                />
                                            </div>
                                            {!isTM && (
                                                <div className='columm-2'>
                                                    <Typography
                                                        variant='subtitle2'
                                                        className={classes.subTitle}
                                                    >
                                                        Bonus
                                                    </Typography>
                                                    <TableSimplePrint
                                                        dataTable={getTableData(
                                                            getColumns(
                                                                'bonus',
                                                                typeAssociate.toLowerCase()
                                                            ),
                                                            item
                                                        )}
                                                    />
                                                </div>
                                            )}

                                            <div className='columm-3'>
                                                <Typography
                                                    variant='subtitle2'
                                                    className={classes.subTitle}
                                                >
                                                    Summary
                                                </Typography>
                                                <TableSimplePrint
                                                    dataTable={getTableData(
                                                        getColumns(
                                                            'summary',
                                                            typeAssociate.toLowerCase()
                                                        ),
                                                        item
                                                    )}
                                                    name={'summary'}
                                                />
                                            </div>
                                            <div className='columm-4'>
                                                <Typography
                                                    variant='subtitle2'
                                                    className={classes.subTitle}
                                                >
                                                    Payroll
                                                </Typography>

                                                <div
                                                    className='table-bonus'
                                                    style={{ marginBottom: '20px' }}
                                                >
                                                    <TableSimplePrint
                                                        dataTable={getTableData(
                                                            getColumns(
                                                                'payroll',
                                                                typeAssociate.toLowerCase()
                                                            ),
                                                            item
                                                        )}
                                                        name={'payroll'}
                                                    />
                                                </div>

                                                <div className='table-bonus'>
                                                    <Typography className='td-bonus-new'>
                                                        {'Christmas bonus'}{' '}
                                                    </Typography>
                                                    <Typography className='td-bonus'>
                                                        {' '}
                                                        {`$${item?.christmast_bonus?.toFixed(2)}`}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            })}
        </div>
    );
};
