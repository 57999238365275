import React from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { useStyles } from './styles';
import PropTypes from 'prop-types';

const dollar = (value) => {
    if (Number.isNaN(Number(value))) return '$0.00';
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
};

export const TablePrint = (props) => {
    const { dataTable, data } = props;
    const classes = useStyles();

    return (
        <TableContainer className='space-between-table'>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell size='small' align='center'>
                            #
                        </TableCell>
                        {dataTable?.columns?.map((column, index) => (
                            <TableCell size='small' align='center' key={index}>
                                {column?.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTable?.data?.map((rowdata, indexRow) => {
                        return (
                            <TableRow key={indexRow}>
                                <TableCell size='small' align='center'>
                                    {indexRow + 1}
                                </TableCell>
                                {dataTable?.columns?.map((column, index) => {
                                    return (
                                        <TableCell
                                            size='small'
                                            className={
                                                index === 0 ? classes.alignLeft : classes.alignRight
                                            }
                                            align={index === 0 ? 'left' : 'right'}
                                            key={index}
                                        >
                                            {column?.dollar
                                                ? dollar(
                                                      rowdata[column?.field] ||
                                                          rowdata[column?.field] == 0
                                                          ? rowdata[column?.field]
                                                          : rowdata[column?.total]
                                                  )
                                                : rowdata[column?.field] ||
                                                  rowdata[column?.field] == 0
                                                ? rowdata[column?.field]
                                                : rowdata[column?.total]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export const TableSimplePrint = (props) => {
    const { dataTable, name } = props;

    const isBold = (row) => {
        if (
            row.includes('TOTAL') ||
            row == 'Subtotal' ||
            row.includes('Plus Adjustment') ||
            row.includes('Bonus For Units Used') ||
            row.includes('Extra Bonus') ||
            row.includes('Unit Volume Bonus')
        ) {
            return true;
        }
    };

    return (
        <TableContainer>
            <Table stickyHeader size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell size='small' align='center'>
                            Description
                        </TableCell>
                        <TableCell size='small' align='center'>
                            Value
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(dataTable).map((row, indexRow) => {
                        return (
                            <TableRow key={indexRow}>
                                <TableCell
                                    size='medium'
                                    align='center'
                                    style={{ wordBreak: 'break-all' }}
                                >
                                    <div
                                        style={{
                                            marginLeft: '70px',
                                            fontWeight: isBold(row) ? 'bold' : 'normal',
                                        }}
                                    >
                                        {row}
                                    </div>
                                </TableCell>
                                <TableCell
                                    size='small'
                                    align='right'
                                    style={{
                                        wordBreak: 'break-all',
                                        fontWeight: isBold(row) ? 'bold' : 'normal',
                                    }}
                                >
                                    {dataTable[row]}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TableSimplePrint.propTypes = {
    dataTable: PropTypes.object,
    name: PropTypes.string,
    dmsCode: PropTypes.string,
};
TableSimplePrint.defaultProps = {
    dataTable: {},
    name: '',
    dmsCode: 0,
};
