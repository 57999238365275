import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import View from './view';
import { snackBarAction } from '../../redux/actions/utilitiesAction';
import { useDispatch, useSelector } from 'react-redux';
import { cleanAdjustmentWashout } from '../../redux/actions/adjustmentAction';
import { washoutEdit, cleanWashout } from '../../redux/actions/washoutAction';
import { validateDateFormat } from '../../consts/fuctions';
import { isLoadingAction } from '../../redux/actions/utilitiesAction';
import {
    adjustmentCreateAction,
    adjustmentDeleteAction,
    adjustmentUpdateAction,
} from '../../redux/actions/adjustmentAction';
import { listTermAction } from '../../redux/actions/termAction';

import {
    detailSubdealAction,
    onDeleteSubDealAction,
    onReverseSubdealAction,
} from '../../redux/actions/subdealApiAction';
import {
    listResponseSelector,
    dmsCurrentWashoutSelector,
    dateCurrentWashoutSelector,
} from '../../redux/selectors';

export default () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const listTerm = useSelector((state) => listResponseSelector(state, 'termReducer'));
    const subDeal = useSelector((state) => listResponseSelector(state, 'subdealApiReducer'));
    const dmsCurrentState = useSelector((state) => dmsCurrentWashoutSelector(state));
    const dateCurrentState = useSelector((state) => dateCurrentWashoutSelector(state));
    const [openTabDetail, setOpenTabDetail] = useState(true);
    const [indexTab, setIndexTab] = useState(0);
    const [typeAssociate, setTypeAssociate] = useState('0');

    const [dataRevert, setDataRevert] = useState({
        month: '',
        year: '',
    });

    function isPositiveOrNegativeNumber(value) {
        // Convert the value to a number if it is a string
        const numberValue = typeof value === 'string' ? Number(value.replace(/,/g, '')) : value;

        // Check if the converted value is a number, not NaN, and not zero
        return typeof numberValue === 'number' && !isNaN(numberValue);
    }

    const [dialogMessageData, setDialogMessageData] = useState({
        open: false,
        type: 'd',
        title: '',
        text: '',
        deal: '',
        associate: '',
    });
    const [addCar, setAddCar] = useState({
        description: '',
        cantN: '0',
        cantU: '0',
    });

    useEffect(() => {
        if (listTerm.length > 0) {
            dispatch(isLoadingAction(false));
        } else {
            dispatch(listTermAction());
        }
    }, []);

    useEffect(() => {
        dispatch(cleanAdjustmentWashout());
        dispatch(cleanWashout());
    }, [location]);
    useEffect(() => {
        if (subDeal) {
            setOpenTabDetail(false);
            setIndexTab(5);
        } else {
            setOpenTabDetail(true);
            setIndexTab(0);
        }
    }, [subDeal]);

    const onEditDinamicTable = (newData, oldData) => {
        dispatch(washoutEdit(newData, oldData));
    };

    const onRowAdd = (obj, newData, data) => {
        if (obj === 'associateWashout') {
            newData = { ...newData, ADJ_DATE: dateCurrentState, CNTL: dmsCurrentState };
            if (!isPositiveOrNegativeNumber(newData.POSTAMTEXT)) {
                dispatch(
                    snackBarAction({
                        open: true,
                        msg: 'Enter a valid number for the amount.',
                        type: 'error',
                    })
                );
                return;
            }
            dispatch(isLoadingAction(true));
            dispatch(adjustmentCreateAction(newData, obj));
        }
    };
    const onRowDelete = (obj, oldData) => {
        if (obj === 'associateWashout') {
            dispatch(isLoadingAction(true));
            dispatch(adjustmentDeleteAction(oldData._id, obj));
        }
    };
    const onRowEdit = (obj, newData, oldData) => {
        if (obj === 'associateWashout') {
            let data = { ...newData };
            data = {
                    ...data,
                    POSTAMTEXT:  data?.POSTAMTEXT?.split('').filter(item => item != '$').join(''),
            };
            if (!isPositiveOrNegativeNumber(data.POSTAMTEXT)) {
                dispatch(
                    snackBarAction({
                        open: true,
                        msg: 'Enter a valid number for the amount.',
                        type: 'error',
                    })
                );
                return;
            }
            dispatch(isLoadingAction(true));
            dispatch(adjustmentUpdateAction(data, obj));
        }
    };

    const validateDate = (newDate, oldDate) => {
        if (validateDateFormat(newDate, dispatch, snackBarAction)) {
            let splitCurrentMonth = oldDate.split('/');
            let splitNewDate = newDate.split('/');
            if (
                splitCurrentMonth[0] === splitNewDate[0] &&
                splitCurrentMonth[2] === splitNewDate[2]
            ) {
                return true;
            } else {
                dispatch(
                    snackBarAction({
                        open: true,
                        msg: 'New month or year is diferent a current month or year',
                        type: 'error',
                    })
                );
            }
            return false;
        }
        return false;
    };

    const handleChangeTab = (event, newValue) => {
        setIndexTab(newValue);
    };
    const openDetail = (rowData) => {
        dispatch(isLoadingAction(true));
        dispatch(detailSubdealAction(dmsCurrentState, rowData.FIWI));
    };
    const handleCloseDialog = () => {
        setDialogMessageData({ ...dialogMessageData, open: false });
    };
    const openMessageConfirmDelete = (rowData, typeMessage) => {
        if (typeMessage == 'd') {
            setDialogMessageData({
                ...dialogMessageData,
                open: true,
                title: 'Comfirm Delete Subdeal',
                text: `Are you sure you want to delete the subdeal ${rowData.FIWI} of associate ${dmsCurrentState}?`,
                deal: rowData.FIWI,
                associate: dmsCurrentState,
                type: 'd',
            });
        }
        if (typeMessage == 'r') {
            setDialogMessageData({
                ...dialogMessageData,
                open: true,
                title: 'Comfirm Reverse Subdeal',
                text: `Selected mouth and year for reverse deal  ${rowData.FIWI} of associate ${dmsCurrentState}?`,
                deal: rowData.FIWI,
                associate: dmsCurrentState,
                type: 'r',
            });
        }
    };
    const onDeleteSubDeal = () => {
        if (dialogMessageData.type == 'd') {
            dispatch(onDeleteSubDealAction(dialogMessageData.associate, dialogMessageData.deal));
        }
        if (dialogMessageData.type == 'r') {
            dispatch(
                onReverseSubdealAction({
                    assoc_number: dialogMessageData.associate,
                    deal_number: dialogMessageData.deal,
                    month: dataRevert.month + dataRevert.year,
                })
            );
        }
        setDialogMessageData({
            ...dialogMessageData,
            open: false,
        });
    };
    const onChangeDataRevert = (e) => {
        setDataRevert({
            ...dataRevert,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangeRadio = (value) => {
        setTypeAssociate(value);
    };

    return (
        <View
            onEditDinamicTable={onEditDinamicTable}
            onRowAdd={onRowAdd}
            onRowDelete={onRowDelete}
            indexTab={indexTab}
            handleChangeTab={handleChangeTab}
            onRowEdit={onRowEdit}
            openTabDetail={openTabDetail}
            openDetail={openDetail}
            dialogMessageData={dialogMessageData}
            handleCloseDialog={handleCloseDialog}
            openMessageConfirmDelete={openMessageConfirmDelete}
            onDeleteSubDeal={onDeleteSubDeal}
            dataRevert={dataRevert}
            onChangeDataRevert={onChangeDataRevert}
            typeAssociate={typeAssociate}
            handleChangeRadio={handleChangeRadio}
        />
    );
};
