import React,{useRef} from 'react';
import { useStyles } from './styles';
import {
    Paper,
    Tabs,
    Tab,
    Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Button,
    CircularProgress,
} from '@material-ui/core';
import {
    SearchSubDeals,
    Details,
    TotalsSubDeals,
    SubDealDetails,
    UnitAdjustment,
} from './components';
import { DinamicTable, SelectDropDown } from '../../components';
import { months, years } from '../../consts/date';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import ReactToPrint from 'react-to-print';
import  PreViewPrint  from './PreViewPrint';

import { isLoading } from '../../redux/selectors';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default (props) => {
    const {
        dialogMessageData,
        handleCloseDialog,
        onDeleteSubDeal,
        dataRevert,
        onChangeDataRevert,
        typeAssociate,
        handleChangeRadio,
    } = props;
    const classes = useStyles();
    const refDivPrint = useRef(null);
    const isLoadingResponse = useSelector((state) => isLoading(state));

    return (
        <div className={classes.root}>
            <SearchSubDeals typeAssociate={typeAssociate} handleChangeRadio={handleChangeRadio} />
            <Dialog
                open={dialogMessageData.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
            >
                <DialogTitle>{dialogMessageData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessageData.text}</DialogContentText>
                    {dialogMessageData.type == 'r' ? (
                        <div style={{ display: 'flex', flexDirection: 'row', width: 400 }}>
                            <SelectDropDown
                                labelName={'Month'}
                                nameSelect={'month'}
                                valueProp={dataRevert.month}
                                onChangeProp={onChangeDataRevert}
                                objectList={months}
                                keyValue={'code'}
                                keyLabel={'name'}
                            />
                            <SelectDropDown
                                labelName={'Year'}
                                nameSelect={'year'}
                                valueProp={dataRevert.year}
                                onChangeProp={onChangeDataRevert}
                                objectList={years}
                                keyValue={'code'}
                                keyLabel={'name'}
                            />
                        </div>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={onDeleteSubDeal}>
                        {dialogMessageData.type == 'd' ? 'Delete' : 'Reverse'}
                    </Button>
                </DialogActions>
            </Dialog>  
            <div style={{
                margin: "2rem auto",
                textAlign: "center",
            }}>
            {isLoadingResponse && <CircularProgress/>}
            </div>
        </div>
    );
};
