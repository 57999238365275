import {
    WASHOUT_ASSOC_SEARCH_START,
    UPDATE_TABLE_SUBDEALS,
    WASHOUT_ASSOC_EDIT_START,
    CLEAN_WASHOUT,
    ALL_WASHOUT_START,
    COMPLETE_ASSOCIATE_LIST_START,
} from '../../consts/actionTypes';

export const washoutSearch = (dms, date, typeAssociate) => ({
    type: WASHOUT_ASSOC_SEARCH_START,
    dms,
    date,
    typeAssociate,
});

export const updateTable = (payload) => ({
    type: UPDATE_TABLE_SUBDEALS,
    payload,
});
export const washoutEdit = (newData, oldData) => ({
    type: WASHOUT_ASSOC_EDIT_START,
    newData,
    oldData,
});
export const cleanWashout = () => ({
    type: CLEAN_WASHOUT,
});
export const allWashoutAction = (payload) => ({
    type: ALL_WASHOUT_START,
    payload,
});

export const getCompleteWashoutSheet = (payload) => ({
    type: COMPLETE_ASSOCIATE_LIST_START,
    payload,
})