export const sellerColumns = [
    {
        title: 'Deal#',
        field: 'FIWI',
        type: 'numeric',
    },
    {
        title: 'Type',
        field: 'DETY',
    },
    {
        title: 'New',
        field: 'new',
        type: 'numeric',
        total: 'total_new_units',
    },
    {
        title: 'Used',
        field: 'used',
        type: 'numeric',
        total: 'total_used_units',
    },
    {
        title: 'Unit',
        field: 'unit',
        type: 'numeric',
        total: 'total_units',
    },
    {
        title: 'ST',
        field: 'ST',
    },
    {
        title: 'SF',
        field: 'SF',
    },
    {
        title: 'SP1',
        field: 'SANO1',
    },
    {
        title: 'SP2',
        field: 'SANO2',
    },
    {
        title: 'FIM',
        field: 'FIMA',
    },
    {
        title: 'Customer Name',
        field: 'customer',
    },
    {
        title: 'Front Gross',
        field: 'deal_front',
        type: 'numeric',
        total: 'total_front',
        dollar: true,
    },
    {
        title: 'Back Gross',
        field: 'deal_back',
        type: 'numeric',
        total: 'total_back',
        dollar: true,
    },
    {
        title: 'GP',
        field: 'gross_profit',
        type: 'numeric',
        total: 'total_gross_profit',
        dollar: true,
    },
    {
        title: 'Comm Front',
        field: 'comm_front',
        type: 'numeric',
        total: 'total_comm_front',
        dollar: true,
    },
    {
        title: 'Comm Back',
        field: 'comm_back',
        type: 'numeric',
        total: 'total_comm_back',
        dollar: true,
    },
    {
        title: 'Book Comm',
        field: 'assoc_pre_comm',
        type: 'numeric',
        total: 'total_book_commm',
        dollar: true,
    },
    {
        title: 'Increase',
        field: 'increase',
        type: 'numeric',
        total: 'total_increase',
        dollar: true,
    },
    {
        title: 'Commission',
        field: 'comm_front_plus_back',
        type: 'numeric',
        total: 'sub_total_commission',
        dollar: true,
    },
];

export const adjustmentColumns = [
    {
        title: 'Acct',
        field: 'ACCT',
    },
    {
        title: 'Description ',
        field: 'CNTL2',
    },
    {
        title: 'NEW/USED',
        field: 'NEW_USED',
        lookup: {
            NEW: 'NEW',
            USED: 'USED',
        },
    },
    {
        title: 'Amount',
        field: 'POSTAMTEXT',
        position: 'right',
        total: 'total_adjustments',
        dollar: true,
    },
];

export const sellerSummery = [
    {
        title: 'Payplan',
        key: 'payplan',
    },
    {
        title: 'Total New Units',
        key: 'totals.total_new_units',
    },
    {
        title: 'Total Used Units',
        key: 'totals.total_used_units',
    },
    {
        title: 'Book Commission',
        key: 'totals.total_book_commm',
        dollar: true,
    },
    {
        title: 'Total Increase',
        key: 'totals.total_increase',
        dollar: true,
    },
];

export const sellerPayroll = [
    {
        title: 'Subtotal Commision',
        key: 'totals.sub_total_commission',
        dollar: true,
    },
    {
        title: 'Subtotal Bonus For Units Sold',
        key: 'totals.sub_total_bonus_units_sold',
        dollar: true,
    },
    {
        title: 'Subtotal',
        key: 'totals.sub_total_commission_plus_bonus',
        dollar: true,
    },
    {
        title: 'Adjustment',
        key: 'totals.sub_total_adjustments_without_draws',
        dollar: true,
    },
    {
        title: 'Subtotal Plus Adjustment',
        key: 'totals.sub_total_commission_plus_bonus_plus_adjs',
        dollar: true,
    },
    {
        title: 'Draw',
        key: 'totals.sub_total_draws',
        dollar: true,
    },
    {
        title: 'TOTAL',
        key: 'totals.total_payroll_table',
        dollar: true,
    },
];

export const sellerBonus = [
    {
        title: 'Bonus For Units New',
        key: 'bonus_new.Unit Volume Bonus',
        dollar: true,
    },
    {
        title: 'Bonus For Units Used',
        key: 'bonus_used.Unit Volume Bonus',
        dollar: true,
    },
];


export const fimBonus = [
    {
        title: 'Extra Bonus',
        key: 'bonus_new.Unit Volume Bonus',
        dollar: true,
    },
];

export const FIMColumns = [
    {
        title: 'Deal#',
        field: 'FIWI',
        type: 'numeric',
    },
    {
        title: 'Type',
        field: 'DETY',
    },
    {
        title: 'New',
        field: 'new',
        type: 'numeric',
        total: 'total_new_units',
    },
    {
        title: 'Used',
        field: 'used',
        type: 'numeric',
        total: 'total_used_units',
    },
    {
        title: 'Unit',
        field: 'unit',
        type: 'numeric',
        total: 'total_units',
    },
    {
        title: 'ST',
        field: 'ST',
    },
    {
        title: 'SF',
        field: 'SF',
    },
    {
        title: 'SP1',
        field: 'SANO1',
    },
    {
        title: 'SP2',
        field: 'SANO2',
    },
    {
        title: 'Customer Name',
        field: 'customer',
    },
    {
        title: 'Back Gross',
        field: 'deal_back',
        type: 'numeric',
        total: 'total_back',
        dollar: true,
    },
    {
        title: 'Commission',
        field: 'comm_front_plus_back',
        type: 'numeric',
        total: 'total_comm_front_plus_back',
        dollar: true,
    },
    {
        title: 'Reserve',
        field: 'reserve_profit',
        type: 'numeric',
        total: 'total_reserve',
        dollar: true,
    },
    {
        title: 'FIC',
        field: 'FICONA',
    },
    {
        title: 'WARR',
        field: 'mbi',
        type: 'numeric',
        total: 'total_count_mbi',
    },
    {
        title: 'VAL',
        field: 'mbi_profit',
        type: 'numeric',
        total: 'total_mbi',
        dollar: true,
    },
    {
        title: 'VREPL',
        field: 'vrepl',
        type: 'numeric',
        total: 'total_count_vehicle_replacement',
    },
    {
        title: 'VAL',
        field: 'vrepl_profit',
        type: 'numeric',
        total: 'total_vehicle_replacement',
        dollar: true,
    },
    {
        title: 'THEFTP',
        field: 'tra_theft',
        type: 'numeric',
        total: 'total_count_theft_program',
    },
    {
        title: 'VAL',
        field: 'tra_theft_profit',
        type: 'numeric',
        total: 'total_theft_program',
        dollar: true,
    },
    {
        title: 'VSHIELD',
        field: 'vshield',
        type: 'numeric',
        total: 'total_count_vshield',
    },
    {
        title: 'VAL',
        field: 'vshield_profit',
        type: 'numeric',
        total: 'total_vshield',
        dollar: true,
    },
    {
        title: 'GAP',
        field: 'gap',
        type: 'numeric',
        total: 'total_count_gap',
    },
    {
        title: 'VAL',
        field: 'gap_profit',
        type: 'numeric',
        total: 'total_gap',
        dollar: true,
    },
    {
        title: 'MAINT',
        field: 'maint',
        type: 'numeric',
        total: 'total_count_vehicle_maintenance',
    },
    {
        title: 'VAL',
        field: 'maint_profit',
        type: 'numeric',
        total: 'total_vehicle_maintenance',
        dollar: true,
    },
    {
        title: 'ADR',
        field: 'rsa',
        type: 'numeric',
        total: 'total_count_rsa',
    },
    {
        title: 'VAL',
        field: 'rsa_profit',
        type: 'numeric',
        total: 'total_rsa',
        dollar: true,
    },
    {
        title: 'KEYR',
        field: 'krepl',
        type: 'numeric',
        total: 'total_count_key_replacement',
    },
    {
        title: 'VAL',
        field: 'krepl_profit',
        type: 'numeric',
        total: 'total_key_replacement',
        dollar: true,
    },
    {
        title: 'ROADH',
        field: 'rhazard',
        type: 'numeric',
        total: 'total_count_road_hazard',
    },
    {
        title: 'VAL',
        field: 'rhazard_profit',
        type: 'numeric',
        total: 'total_road_hazard',
        dollar: true,
    },
    {
        title: 'NITRO',
        field: 'nitro',
        type: 'numeric',
        total: 'total_count_nitro',
    },
    {
        title: 'VAL',
        field: 'nitro_profit',
        type: 'numeric',
        total: 'total_nitro',
        dollar: true,
    },
    {
        title: 'PAINT_DENT',
        field: 'paint_dent',
        type: 'numeric',
        total: 'total_count_paint_dent',
    },
    {
        title: 'VAL',
        field: 'paint_dent_profit',
        type: 'numeric',
        total: 'total_paint_dent',
        dollar: true,
    },
    {
        title: 'AWHEEL',
        field: 'alloy',
        type: 'numeric',
        total: 'total_count_alloy_wheel',
    },
    {
        title: 'VAL',
        field: 'alloy_profit',
        type: 'numeric',
        total: 'total_alloy_wheel',
        dollar: true,
    },
    {
        title: 'EWT',
        field: 'ex_wear',
        type: 'numeric',
        total: 'total_count_excess_wear',
    },
    {
        title: 'VAL',
        field: 'ex_wear_profit',
        type: 'numeric',
        total: 'total_excess_wear',
        dollar: true,
    },
    {
        title: 'AUBU',
        field: 'aubu',
        type: 'numeric',
        total: 'total_count_auto_butler',
    },
    {
        title: 'VAL',
        field: 'aubu_profit',
        type: 'numeric',
        total: 'total_auto_butler',
        dollar: true,
    },
    {
        title: 'CDS-GPS',
        field: 'cds_gps',
        type: 'numeric',
        total: 'total_count_cds_gps',
    },
    {
        title: 'VAL',
        field: 'cds_gps_profit',
        type: 'numeric',
        total: 'total_cds_gps',
        dollar: true,
    },
    {
        title: 'APPEAR',
        field: 'appearance_pkg',
        type: 'numeric',
        total: 'total_count_appearance_pkg',
    },
    {
        title: 'VAL',
        field: 'appearance_pkg_profit',
        type: 'numeric',
        total: 'total_appearance_pkg',
        dollar: true,
    },
];

export const FIMSummery = [
    {
        title: 'Total Core Products',
        key: 'totals.total_core',
        dollar: true,
        whiteSpace: true,
    },
    {
        title: 'Total Other Products',
        key: 'totals.total_other',
        dollar: true,
        whiteSpace: true,
    },
    {
        title: 'Total Finance Reserve',
        key: 'totals.total_fin',
        dollar: true,
        whiteSpace: true,
    },
    {
        title: 'Total Products',
        key: 'totals.total_product_back',
        dollar: true,
    },
    {
        title: 'Product',
        key: 'totals.total_products',
    },
    {
        title: 'Level',
        key: 'level',
    },
    {
        title: 'Penetration',
        key: `penetration`,
        percentage: true,
    },
];
export const FIMPayrol = [
    {
        title: 'Subtotal Commision',
        key: 'totals.total_comm_front_plus_back',
        dollar: true,
    },
    {
        title: 'Extra Commission',
        key: 'totals.sub_total_bonus_units_sold',
        dollar: true,
    },
    {
        title: 'Subtotal',
        key: 'totals.sub_total_commission_plus_bonus',
        dollar: true,
    },
    {
        title: 'Adjustment',
        key: 'totals.sub_total_adjustments_without_draws',
        dollar: true,
    },
    {
        title: 'Subtotal Plus Adjustment',
        key: 'totals.sub_total_commission_plus_bonus_plus_adjs',
        dollar: true,
    },
    {
        title: 'Draw',
        key: 'totals.sub_total_draws',
        dollar: true,
    },
    {
        title: 'TOTAL',
        key: 'totals.total_payroll_table',
        dollar: true,
    },
];

export const TMColumns = [
    {
        title: 'Salesperson',
        field: 'associate_name',
    },
    {
        title: 'New',
        field: 'total_new_units',
        type: 'numeric',
        total: 'team_total_new_units',
    },
    {
        title: 'Used',
        field: 'total_used_units',
        type: 'numeric',
        total: 'team_total_used_units',
    },
    {
        title: 'Unit',
        field: 'total_units',
        type: 'numeric',
        total: 'team_total_units',
    },
    {
        title: 'Hold back',
        field: 'total_hb',
        type: 'numeric',
        total: 'team_total_hb',
        dollar: true,
    },
    {
        title: 'Front Gross',
        field: 'total_front',
        type: 'numeric',
        total: 'team_total_front',
        dollar: true,
    },
    {
        title: 'Back Gross',
        field: 'total_back',
        type: 'numeric',
        total: 'team_total_back',
        dollar: true,
    },
    {
        title: 'F&B',
        field: 'total_front_back',
        type: 'numeric',
        total: 'team_total_front_back',
        dollar: true,
    },
    {
        title: '%F&B',
        field: 'total_comm_front_plus_back',
        type: 'numeric',
        total: 'team_total_comm_front_plus_back',
        dollar: true,
    },
];

export const TMSummery = [
    {
        title: 'Total F&B + HB',
        key: 'totals.team_total_front_back_plus_hb_plus_dap',
        dollar: true,
    },
    {
        title: 'Level',
        key: 'level',
    },
];

export const TMSPayrol = [
    {
        title: 'Subtotal Commission',
        key: 'totals.team_total_comm_front_plus_back',
        dollar: true,
    },
    {
        title: 'Unit Volume Bonus',
        key: 'bonus.Unit Volume Bonus',
        dollar: true,
    },
    {
        title: 'Adjustment',
        key: 'totals.sub_total_adjustments_without_draws',
        dollar: true,
    },
    {
        title: 'Subtotal Plus Adjustment',
        key: 'totals.sub_total_commission_plus_bonus_plus_adjs',
        dollar: true,
    },
    {
        title: 'Draw',
        key: 'totals.sub_total_draws',
        dollar: true,
    },
    {
        title: 'TOTAL',
        key: 'totals.total_payroll_table',
        dollar: true,
    },
];
